<template>
	<client-only>
		<div v-show="showConsentPanel && cf" class="popup">
			<div class="popup-row">
				<div class="popup-body" :class="currentStep">
					<lang-select :items="page.langmenu" />
					<div v-if="currentStep === 'consent'">
						<h3>{{ item.header }}</h3>
						<div v-parse-links v-html="item.content" />
						<button class="button button-cta fill" @click="acceptAllCategories">
							{{ $t('consentAcceptAll') }}
						</button>
						<button class="button button-no-fill" @click="declineAllCategories">
							{{ $t('consentRejectAll') }}
						</button>
						<button class="button button-no-fill" @click="currentStep = 'settings'">
							{{ $t('consentSettings') }}
						</button>
					</div>
					<div v-else-if="currentStep === 'settings'">
						<h3>{{ item.headerConsentSettings }}</h3>
						<div class="consent-category">
							<input id="necessary" v-model="consent.necessary" type="checkbox" disabled />
							<h4>
								<label for="necessary">{{ $t('consentCategoryNecessary') }}</label>
							</h4>
							<div />
							<div class="category-content">
								<div v-parse-links v-html="item.contentNecessary" />
							</div>
						</div>
						<div class="consent-category">
							<input id="performance" v-model="consent.performance" type="checkbox" />
							<h4>
								<label for="performance">{{ $t('consentCategoryPerformance') }}</label>
							</h4>
							<div />
							<div class="category-content">
								<div v-parse-links v-html="item.contentPerformance" />
							</div>
						</div>
						<div class="consent-category">
							<input id="functional" v-model="consent.functional" type="checkbox" />
							<h4>
								<label for="functional">{{ $t('consentCategoryFunctional') }}</label>
							</h4>
							<div />
							<div class="category-content">
								<div v-parse-links v-html="item.contentFunctional" />
							</div>
						</div>
						<div class="consent-category">
							<input id="advertising" v-model="consent.advertising" type="checkbox" />
							<h4>
								<label for="advertising">{{ $t('consentCategoryAdvertising') }}</label>
							</h4>
							<div />
							<div class="category-content">
								<div v-parse-links v-html="item.contentAdvertising" />
							</div>
						</div>
						<button class="button button-cta fill" @click="updateConsent">
							{{ $t('consentSaveAndClose') }}
						</button>
						<button class="button button-no-fill" @click="currentStep = 'consent'">
							{{ $t('back') }}
						</button>
					</div>
				</div>
			</div>
			<div class="popup-background" />
		</div>
	</client-only>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { openConsent, cfInit } = useCookieConsent();

await fetchPage();

defineProps({
	item: {
		type: Object,
		default: () => ({
			content: '',
			contentNecessary: '',
			contentPerformance: '',
			contentFunctional: '',
			contentAnalytics: '',
		}),
	},
});

const showConsentPanel = useState('showConsentPanel', () => false);
const currentStep = ref('consent');
const cf = useState('cf', () => {});

const consent = reactive({
	necessary: true,
	performance: false,
	functional: false,
	advertising: false,
});

onMounted(() => {
	cfInit(initCookieFirst);
});

const initCookieFirst = () => {
	if (!window.CookieFirst) {
		return;
	}

	cf.value = window.CookieFirst;

	if (!cf.value.consent) {
		openConsent();
	}
};

const closeConsent = () => {
	showConsentPanel.value = false;
	document.body.classList.remove('locked');
};

const acceptAllCategories = () => {
	if (!cf.value) {
		return;
	}

	cf.value.acceptAllCategories();
	closeConsent();
};

const declineAllCategories = () => {
	if (!cf.value) {
		return;
	}

	cf.value.declineAllCategories();
	closeConsent();
};

const updateConsent = () => {
	if (!cf.value) {
		return;
	}

	cf.value.updateConsent({ ...consent });
	closeConsent();
};
</script>

<style lang="scss" scoped>
:deep(p) {
	margin-top: 0;
}

.popup {
	position: fixed;
	inset: 0;
	z-index: 99999;

	h3 {
		margin-bottom: 20px;
	}

	.button {
		margin-right: 10px;
	}

	.popup-background {
		background: rgb(0 0 0 / 50%);
		position: absolute;
		inset: 0;
		z-index: 1;
	}

	.popup-row {
		display: flex;
		flex-flow: wrap row;
		justify-content: space-between;
		align-items: stretch;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		max-width: 900px;
		z-index: 3;
		max-height: 100%;
		overflow-y: auto;
		background: #fff;

		&.no-img {
			justify-content: center;
		}
	}

	.popup-image {
		width: 50%;

		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	.popup-body {
		position: relative;
		background: var(--primary-background-color);
		z-index: 2;
		padding: 70px 60px;

		.popup-button {
			position: absolute;
			top: 17px;
			right: 24px;
			color: #000;
			cursor: pointer;
			font-size: 30px;
		}

		.popup-content {
			float: left;
			width: 100%;
			padding: 15px 0 0;
		}
	}

	.consent-category {
		display: grid;
		grid-template-columns: 25px 1fr;
		gap: 5px 0;

		input {
			max-width: 15px;
			display: flex;
			align-self: center;

			&:hover {
				cursor: pointer;

				&:disabled {
					cursor: not-allowed;
				}
			}
		}

		h4 {
			display: flex;
			align-self: center;
			margin-bottom: 0;
			font-size: 1.3em;
			line-height: normal;
		}

		label:hover {
			cursor: pointer;
		}
	}
}

.lang-select {
	position: absolute;
	top: 15px;
	right: 10px;
}
</style>
